<template lang="pug">
	v-container(fill-height align="center"  justify="center")
		v-row( align="center"  justify="center" )
			v-col( cols="12" sm="12" md="6" class="text-center")
				v-alert(type="success" color="blue blue-700" dark dense style="margin-top: -15px;" v-if="message") Su contraseña ha sido restablecida. 
					a(class="text-capitalize white--text text-decoration-underline" @click="$router.push({name: 'login'})") Iniciar Sesión
		v-row( align="center"  justify="center" )
			v-col( cols="12" sm="6" lg="5" class="text-center")
				span(class="display-2 mb-10 blue-500--text") Analítica Coppel Omnicanal
				v-spacer
				v-spacer
				v-icon(size="150" color="blue-400" class="mt-10") mdi-chart-bell-curve-cumulative
			v-col( cols="12" sm="6" lg="5")
				v-card( class="elevation-12")
					v-card-text
						v-form
							h2(class="title  font-weight-bold black--text text-center mb-10") Restablece tu contraseña
							
							v-text-field( prepend-icon="mdi-lock"  placeholder=" " label="Nueva contraseña" type="password" v-model="password" autocomplete="off" )
							v-text-field( prepend-icon="mdi-lock"  placeholder=" " label="Repite tu nueva contraseña" type="password" v-model="password2" autocomplete="off")
							transition(name="slide-fade" mode="out-in")
								template(v-if="errors")
									ul
										li(v-for="item of errors" class="red--text" style="list-style: none;") <b> {{item}}</b>

					v-card-actions
						v-spacer
						v-btn( color="blue-500" 
							class="white--text text-none"
							:loading="loading"
							:disabled="loading"
							@click="resetPasswordConfirm()" v-if="!message" ) Restablecer Contraseña
</template>
<script>

	import Users from '../services/Users'
	const users = new Users();

	export default {
		props: {
			routeForm: String
		},
		data () {
			return {
				loading		: false,
                password: '',
                password2: '',
				errors 		: [],
				message: false
			}
		},
		methods: {
			resetPasswordConfirm:function(){

				// validacón
				if(!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(this.password)) {
					this.errors = [
						'Se requiere al menos una mayúscula, un número y un cáracter especial.',
						];
					return;
				}

				this.errors = [];
				let uid = this.$route.params.uid; 
				let token = this.$route.params.token; 
				var  data = {
					uid: uid,
					token: token,
					new_password: this.password,
					re_new_password: this.password2,
				};

				this.loading = true;
				
				users.resetPasswordConfirm( data )
				.then( (response )=>{
                    this.loading = false;
                    this.email = '';
					this.password = '';
					this.password2 = '';
					this.message = true;
				})
				.catch(error => {
					var res = error.response.data;
					var err = Object.values(res);
					this.loading = false;
					this.errors = typeof err == 'object' ? err[0] : ['error'];
				}) 
			}
		},
	}
</script>